import React, { useState, useEffect } from 'react';
import css from './MegaMenuMobile.module.css';
import Collapsible from 'react-collapsible';
import { getMegaMenuData } from '../../megaMenuData';
import NamedLink from '../NamedLink/NamedLink';

import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
const MegaMenuMobile = ({ lang }) => {
  const data = getMegaMenuData(lang);
  const [open, setOpen] = useState({});
  const [openSub, setOpenSub] = useState({});
  const [click, setClick] = useState(false);
  const history = useHistory();
  const search = history.location.search;
  const isMobileMenuOpen = search?.includes('mobilemenu=open');
  const handleClick = url => {
    setClick(!click);
    history.push(`${url}`);
  };
  useEffect(() => {
    setOpen({});
    setOpenSub({});
  }, [isMobileMenuOpen]);
  useEffect(() => {
    setOpen({});
    setOpenSub({});
  }, [click]);

  return (
    <div className={css.root}>
      {data?.map((item, index) => {
        const { main, column = [], url, clickable, key } = item;
        return (
          <Collapsible
            trigger={
              clickable ? (
                <label
                  htmlFor="text"
                  className={css.trigger}
                  onClick={() => {
                    setOpen({
                      ...open,
                      [index]: !open[index],
                      // [index]: !open[index],
                    });
                  }}
                >
                  <div
                    // name="SearchPage"
                    // to={{
                    //   search: `?pub_${main?.url}=${main.key}`,
                    // }}
                    onClick={() => {
                      handleClick(main?.url);
                      // history.push(`${main?.url}`);
                    }}
                    className={css.triggerText}
                  >
                    {main.label}
                  </div>
                  {column?.length > 0 && (
                    <div className={css.iconContainer}>
                      {open[index] ? (
                        <IoIosArrowUp className={css.downIcon} />
                      ) : (
                        <IoIosArrowDown className={css.downIcon} />
                      )}
                    </div>
                  )}
                </label>
              ) : (
                <label
                  htmlFor="text"
                  className={css.trigger}
                  onClick={() => {
                    setOpen({
                      ...open,
                      [index]: !open[index],
                    });
                  }}
                >
                  <div className={css.triggerText}>{main.label}</div>
                  {column?.length > 0 && (
                    <div className={css.iconContainer}>
                      {open[index] ? (
                        <IoIosArrowUp className={css.downIcon} />
                      ) : (
                        <IoIosArrowDown className={css.downIcon} />
                      )}
                    </div>
                  )}
                </label>
              )
            }
            transitionTime={300}
            key={index}
            open={open[index]}
          >
            <div className={css.columns}>
              {column?.map((col, index) => {
                const { title, sub } = col;
                return title ? (
                  <Collapsible
                    trigger={
                      <label
                        htmlFor="text"
                        className={css.triggerSub}
                        onClick={() => {
                          setOpenSub({
                            ...openSub,
                            [index]: !openSub[index],
                          });
                        }}
                      >
                        <div className={css.triggerText}>{title}</div>
                        {sub?.length > 0 && (
                          <div className={css.iconContainer}>
                            {openSub[index] ? (
                              <IoIosArrowUp className={css.downIcon} />
                            ) : (
                              <IoIosArrowDown className={css.downIcon} />
                            )}
                          </div>
                        )}
                      </label>
                    }
                    key={index}
                    transitionTime={300}
                    open={openSub[index]}
                  >
                    <ul className={css.collapsibleBody}>
                      {sub?.map((subItem, index) => {
                        return (
                          <li key={index} className={css.li}>
                            <div
                              // name="SearchPage"
                              // to={{
                              //   search: `?pub_${url}=${subItem.key}`,
                              // }}
                              onClick={() => {
                                handleClick(subItem.url);
                                // history.push(`${subItem.url}`);
                              }}
                              className={css.label}
                            >
                              {subItem.label}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </Collapsible>
                ) : (
                  <ul className={css.collapsibleBody}>
                    {sub?.map((subItem, index) => {
                      return (
                        <li key={index} className={css.li}>
                          <div
                            // name="SearchPage"
                            // to={{
                            //   search: `?pub_${url}=${subItem.key}`,
                            // }}
                            onClick={() => {
                              handleClick(subItem.url);
                              // history.push(`${subItem.url}`);
                            }}
                            className={css.label}
                          >
                            {subItem.label}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </div>
          </Collapsible>
        );
      })}
    </div>
  );
};

export default MegaMenuMobile;
