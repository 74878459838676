import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'privacy-policy';
export const GREEK_ASSET_NAME = 'privacy-policy-greek'
import { languageType } from '../../util/languageType';


export const loadData = (params, search) => dispatch => {
  const pageAsset = { privacyPolicy: `content/pages/${languageType()=="greek"? GREEK_ASSET_NAME:ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
