import {
  defaultColor,
  defaultCondition,
  defaultMaterial,
  marketplaceData,
  configLabels,
} from '../marketplace-data';

const categoryOptions = marketplaceData;
export const colorOptions = defaultColor;
export const conditionOptions = defaultCondition;
export const materialOptions = defaultMaterial;

export const conditionFilter = {
  key: 'condition',
  scope: 'public',
  schemaType: 'enum',
  enumOptions: [...conditionOptions],
  filterConfig: {
    indexForSearch: true,
    filterType: 'SelectMultipleFilter',
    label: configLabels?.find(label => label.value === 'condition')?.label || 'Condition',
    group: 'primary',
  },
};
export const materialFilter = {
  key: 'material',
  scope: 'public',
  schemaType: 'enum',
  enumOptions: [...materialOptions],
  filterConfig: {
    indexForSearch: true,
    filterType: 'SelectMultipleFilter',
    label: configLabels?.find(label => label.value === 'material')?.label || 'Material',
    group: 'primary',
  },
};
export const colorFilter = {
  key: 'colour',
  scope: 'public',
  schemaType: 'enum',
  enumOptions: [...colorOptions],
  filterConfig: {
    indexForSearch: true,
    filterType: 'SelectMultipleFilter',
    label: configLabels?.find(label => label.value === 'colour')?.label || 'Colour',
    group: 'primary',
  },
};

export const listingFields = [
  {
    key: 'department',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [...categoryOptions],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: configLabels?.find(label => label.value === 'department')?.label || 'Department',
      group: 'primary',
    },
  },
];
export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
