import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import css from './ChangeLanguageForm.module.css';
import { useDispatch } from 'react-redux';
import { updateProfileLanguage } from '../../ducks/user.duck';
import { TbWorld } from 'react-icons/tb';
import MenuLabel from '../MenuLabel/MenuLabel';
import MenuContent from '../MenuContent/MenuContent';
import MenuItem from '../MenuItem/MenuItem';
import Menu from '../Menu/Menu';
import { languageType } from '../../util/languageType';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { IoIosCheckmark } from 'react-icons/io';

// import { Helmet } from 'react-helmet';
const ChangeLanguageFormComponent = props => {
  const { intl, currentUser } = props;
  const [value, setValue] = useState('el');
  const { LandingPage } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'en-US';
    if (cookieValue == 'en-US') {
      setValue('en-US');
    } else {
      setValue('el');
    }
  }, []);

  const handleOnChange = async event => {
    const selectedOption = event.currentTarget.getAttribute('language');
    setValue(selectedOption);
    await dispatch(updateProfileLanguage(selectedOption));
    document.cookie = 'language=' + selectedOption;
    window.location.reload(true);
  };
  const englishLanguage = intl.formatMessage({
    id: 'ChangeLanguageForm.englishLanguage',
  });
  const greekLanguage = intl.formatMessage({
    id: 'ChangeLanguageForm.greekLanguage',
  });
  const selectedLanguage =
    value === 'en-US'
      ? intl.formatMessage({
          id: 'ChangeLanguageForm.englishLanguageSelected',
        })
      : intl.formatMessage({
          id: 'ChangeLanguageForm.greekLanguageSelected',
        });
  const languageOptions = (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <div className={css.changeLanguageForm}>
          <TbWorld className={css.worldIcon} />
          <div className={css.selectedLabel}>{languageType() === 'en-US' ? 'EN' : 'EL'}</div>
          <MdKeyboardArrowDown />
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="">
          <div className={css.option} language="en-US" onClick={e => handleOnChange(e)}>
            <div className={css.label}>{englishLanguage}</div>
            {languageType() === 'en-US' ? <IoIosCheckmark className={css.checkMark} /> : null}
          </div>
        </MenuItem>
        <MenuItem key="hhhh">
          <div className={css.optionSecond} language="el" onClick={e => handleOnChange(e)}>
            <div className={css.label}>{greekLanguage}</div>
            {languageType() === 'el' ? <IoIosCheckmark className={css.checkMark} /> : null}
          </div>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
  return <div className={css.changeFormContainer}>{languageOptions}</div>;
};

const ChangeLanguageForm = injectIntl(ChangeLanguageFormComponent);

export default ChangeLanguageForm;
