export const languageType = () => {
  let languageCokieValue;
  if (typeof window != 'undefined') {
    languageCokieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'el';
  } else {
    languageCokieValue = 'el';
  }
  // console.log('faisal', languageCokieValue);
  return languageCokieValue;
};
