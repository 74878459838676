
import { flip } from "lodash"
import { languageType } from "../util/languageType"
console.log('languageType', languageType())
export const cookieConfig = {
  onChange: ()=> {
   window.location.reload()
  },

  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom",
      equalWeightButtons: true,
      flipButtons: true,
      closeIconLabel: "Close modal",
    },
    preferencesModal: {
        layout: "box",
        position: "right",
        equalWeightButtons: true,
        flipButtons: true
    }
},


  mode: 'opt-in',
    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
      //   analytics: {
      //     enabled: true,
      //     services: {
      //         GoogleAnalytics: {
      //           label: 'GoogleAnalytics',
      //           onAccept: () => {
                  
      //           },
      //           onReject: () => {
      //             window[`ga-disable-${googleAnalyticsId}`] = true;
      //           },
      //           cookies: [
      //             {
      //               name: /^(_ga)/,                  }
      //             ]
      
      //         },
      //         FacebookPixel: {
      //           // enabled: true,
      //           label: 'Meta Pixel',
      //           onAccept: () => {
                  
      //           },
      //           onReject: () => {
                  
      //           },
      //           cookies: [
      //             {
      //               name: /_fbp/,
                    
      //             }
      //             ]
      
      //         },
      //         HotJar: {
      //           // enabled: true,
      //           label: 'HotJar',
      //           onAccept: () => {
                  
      //           },
      //           onReject: () => {
                  
      //           },
      //           cookies: [
      //             {
      //               name: /_hjr/,
                    
      //             }
      //             ]
      
      //         },
      //         // GoogleTagManager: {
      //         //   label: 'Google Tag Manager',
      //         //   onAccept: () => {
      //         //     
      //         //   },
      //         //   onReject: () => {
                  
      //         //   },
      //         //   cookies: [
      //         //     {
      //         //       name: /_ga/,
                    
      //         //     }
      //         //     ]
      
      //         // },
      // }
        
      //   }
    },

    language: {
      default: `${languageType()==='el'?'el':'en'}`,
        translations: {
            'en': {
                consentModal: {
                    title: 'This website uses cookies',
                    description: ` We use cookies to ensure the basic functionalities of the website and to enhance your online experience. <a href="#path-to-impressum.html" target="_blank">Learn more</a>`,
                    acceptAllBtn: 'Accept all',
                    //acceptNecessaryBtn: 'Reject all',
                    showPreferencesBtn: 'Manage cookies',
                    closeIconLabel: "Close modal",
                    acceptNecessaryBtn: "Allow only essential cookies",
                    
                },
                preferencesModal: {
                    title: 'Manage cookie preferences',
                    acceptAllBtn: 'Accept',
                    // acceptNecessaryBtn: 'Reject all',
                    // savePreferencesBtn: 'Accept current selection',
                    acceptNecessaryBtn: "Reject all",
                    closeIconLabel: 'Close modal',
                    sections: [
                        {
                            title: 'Cookie Usage',
                            description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. For more details relative to cookies and other sensitive data.'
                        },
                        {
                            title: 'Strictly Necessary cookies',
                            description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary',
                            cookieTable: {
                              headers: {
                                name: 'Name',
                                domain: 'Service',
                                description: 'Description',
                                expiration: 'Expiration',
                              },
                              body: [
                                {
                                  name: 'st-hosted-token',
                                  domain: 'www.popnouvelle.com',
                                  description:
                                    'The Sharetribe cookie is only used to session information',
                                  expiration: '1 Month',
                                },
                                {
                                    name: 'Language',
                                    domain: 'www.popnouvelle.com',
                                    description:
                                      'Save Users Language Preference',
                                    expiration: 'Session',
                                  },
                                 
                                 
                                  
                                  {
                                    name: '__stripe_mid',
                                    domain: 'www.popnouvelle.com',
                                    description: 'Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction. ',
                                    expiration: '1 year',
                                  },
                                  {
                                    name: '__stripe_sid',
                                    domain: 'www.popnouvelle.com',
                                    description: 'Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction. Learn more about ',
                                    expiration: '30 minutes',
                                  },                    
                              ],
                              
                            },
                        },
                        
                        // {
                        //     title: 'Performance and Analytics',
                        //     description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                        //     linkedCategory: 'analytics'
                        // },
                        {
                            title: 'More information',
                            description: 'For any queries in relation to my policy on cookies and your choices, please contact us.'
                        },
                        
                    ]
                }
            },
            'el': {
              consentModal: {
                  title: 'Αυτός ο ιστότοπος χρησιμοποιεί cookies',
                  description: `Χρησιμοποιούμε cookies για να σας προσφέρουμε μια πιο προσωποποιημένη εμπειρία. <a href="#path-to-impressum.html" target="_blank">Μάθετε περισσότερα</a>`,
                  acceptAllBtn: 'Αποδοχή όλων',
                  //acceptNecessaryBtn: 'Απόρριψη όλων',
                  showPreferencesBtn: 'Διαχείριση cookies',
                  closeIconLabel: "Κλείσιμο modal",
                  acceptNecessaryBtn: "Να επιτρέπονται μόνο τα απαραί cookies",
                  
              },
              preferencesModal: {
                  title: 'Διαχείριση προτιμήσεων cookies',
                  acceptAllBtn: 'Αποδοχή',
                  // acceptNecessaryBtn: 'Απόρριψη όλων',
                  // savePreferencesBtn: 'Αποδοχή τρέχουσας επιλογής',
                  acceptNecessaryBtn: "Απόρριψη όλων",
                  closeIconLabel: 'Κλείσιμο modal',
                  sections: [
                      {
                          title: 'Χρήση Cookies',
                          description: 'Χρησιμοποιούμε cookies για να διασφαλίσουμε τις βασικές λειτουργίες του ιστότοπου και για να βελτιώσουμε την εμπειρία σας στο διαδίκτυο. Για περισσότερες λεπτομέρειες σχετικά με τα cookies και άλλα ευαίσθητα δεδομένα.'
                      },
                      {
                          title: 'Απολύτως Απαραίτητα Cookies',
                          description: 'Αυτά τα cookies είναι ουσιαστικά για τη σωστή λειτουργία του ιστότοπου και δεν μπορούν να απενεργοποιηθούν.',
          
                          //this field will generate a toggle linked to the 'necessary' category
                          linkedCategory: 'necessary',
                          cookieTable: {
                            headers: {
                              name: 'Όνομα',
                              domain: 'Υπηρεσία',
                              description: 'Περιγραφή',
                              expiration: 'Λήξη',
                            },
                            body: [
                              {
                                name: 'st-hosted-token',
                                domain: 'www.popnouvelle.com',
                                description:
                                  'Το cookie Sharetribe χρησιμοποιείται μόνο για πληροφορίες συνεδρίας',
                                expiration: '1 Μήνας',
                              },
                              {
                                  name: 'Γλώσσα',
                                  domain: 'www.popnouvelle.com',
                                  description:
                                    'Αποθηκεύει τις προτιμήσεις γλώσσας του χρήστη',
                                  expiration: 'Συνεδρία',
                                },
                               
                               
                                
                                {
                                  name: '__stripe_mid',
                                  domain: 'www.popnouvelle.com',
                                  description: 'Ορίζεται για σκοπούς πρόληψης απάτης και μας βοηθά να αξιολογήσουμε τον κίνδυνο που σχετίζεται με μια προσπάθεια συναλλαγής.',
                                  expiration: '1 χρόνος',
                                },
                                {
                                  name: '__stripe_sid',
                                  domain: 'www.popnouvelle.com',
                                  description: 'Ορίζεται για σκοπούς πρόληψης απάτης και μας βοηθά να αξιολογήσουμε τον κίνδυνο που σχετίζεται με μια προσπάθεια συναλλαγής. Μάθετε περισσότερα για',
                                  expiration: '30 λεπτά',
                                },                    
                            ],
                            
                          },
                      },
                      
                      // {
                      //     title: 'Επιδόσεις και Αναλυτικά',
                      //     description: 'Αυτά τα cookies συλλέγουν πληροφορίες σχετικά με το πώς χρησιμοποιείτε τον ιστότοπό μας. Όλα τα δεδομένα είναι ανωνυμοποιημένα και δεν μπορούν να χρησιμοποιηθούν για να σας ταυτοποιήσουν.',
                      //     linkedCategory: 'analytics'
                      // },
                      {
                          title: 'Περισσότερες πληροφορίες',
                          description: 'Για οποιαδήποτε ερωτήματα σχετικά με την πολιτική μου σχετικά με τα cookies και τις επιλογές σας, παρακαλώ επικοινωνήστε μαζί μας.'
                      },
                      
                  ]
              }
          },

            
              
            
            
          } 
}
}
    




  
 
